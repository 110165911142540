import React, { useState } from 'react';
import { fetchBandF } from '../lib/api';
import './BackAndForth.css';
import CallToAction from './CallToAction';
import DOMPurify from 'dompurify';
import Spinner from "@cloudscape-design/components/spinner";
import Cards from "@cloudscape-design/components/cards";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";

const BackAndForth = ({ onInteract, userEntity, prompt, isAnswer, gptContent, userInputs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState('');

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    let newUserInputs = userInputs ? [...userInputs, userInput] : [userInput];
    let newGptContent = gptContent ? [...gptContent] : [];

    try {
      const response = await fetchBandF(userEntity, newUserInputs, prompt, newGptContent);
      
      if (response.status !== 200) {
        onInteract({ name: 'API Response not 200', message: response.statusText }, true);
      } else {
        const isAnswerFromApi = response.data[0] === "1";
        
        if (!isAnswerFromApi) {
            
          newGptContent = [...newGptContent, response.data[1]];  // Corrected this line
          onInteract({ 
            replaceAll: false, 
            name: 'BackAndForth', 
            component: <BackAndForth 
                          onInteract={onInteract} 
                          userEntity={userEntity} 
                          prompt={prompt} 
                          isAnswer={isAnswerFromApi} 
                          gptContent={newGptContent} 
                          userInputs={newUserInputs} 
                      /> 
          });
        } else {
          onInteract({ 
            replaceAll: true, 
            name: 'CallToAction', 
            component: <CallToAction 
                          onInteract={onInteract} 
                          gptContent={response.data[1]} 
                      /> 
          });
        }
      }
    } catch (error) {
      onInteract({ name: 'Error of other sorts', message: error.message }, true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ justifyContent: 'left', alignItems: 'left', padding: '30px' }}>
      <div className="cardFadeIn">
        <Cards
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 500, cards: 2 }
          ]}
          items={[
            {
              name: gptContent && gptContent.length > 0 ? DOMPurify.sanitize(gptContent[gptContent.length - 1]) : '',
              input: (
                <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <Input 
                    type="text" 
                    value={userInput} 
                    onChange={({ detail }) => setUserInput(detail.value)} 
                    placeholder="Your answer here..."
                  />
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner /> : "Submit"}
                  </Button>
                </form>
              )
            }
          ]}
          cardDefinition={{
            header: item => item.name,
            sections: [
              {
                id: "description",
                header: "Description",
                content: item => item.description
              },
              {
                id: "input",
                content: item => item.input
              }
            ]
          }}
        />
      </div>
    </div>
);
};

export default BackAndForth;