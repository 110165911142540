import React, { useState } from 'react';
import { fetchBandF } from '../lib/api';
import BackAndForth from './BackAndForth';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Spinner from "@cloudscape-design/components/spinner";
import './Prompts.css';

const Prompts = ({ items, onInteract, userEntity }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (prompt) => {
    setIsLoading(true);
    try {
      const response = await fetchBandF(userEntity, null, prompt);
      if (response.status !== 200) {
        onInteract({ name: 'API Response not 200', message: response.statusText }, true);
      } else {
        const isAnswer = response.data[0] === "1";
        const gptContent = [response.data[1]];
        onInteract({ replaceAll: true, name: 'BackAndForth', component: <BackAndForth onInteract={onInteract} userEntity={userEntity} prompt={prompt} isAnswer={isAnswer} gptContent={gptContent} /> });
      }
    } catch (error) {
      onInteract({ name: 'Error of other sorts', message: error.message }, true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="prompts">
      <h1 className="promptsHeader">Choose a prompt</h1>
      <SpaceBetween size="l">
        {items.map((prompt, index) => (
          <div 
            key={index}
            onClick={() => handleClick(prompt)}
            className={`promptItem fadeInFromLeft ${isLoading ? 'disabled' : ''}`}
          >
            <Container
              header={
                <Header variant="h2">{prompt}</Header>
              }
            />
          </div>
        ))}
      </SpaceBetween>
      {isLoading && <Spinner size='large'/>}
    </div>
  );
};

export default Prompts;
