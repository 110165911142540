import React, { useState, useEffect } from 'react';
import WelcomeMessage from '../components/WelcomeMessage';
import ErrorComponent from '../components/ErrorComponent';
import '../styles/styles.css';

const App = () => {
  const [convo, setConvo] = useState([]);

  const handleInteraction = (object, isError = false) => {
    console.log('handleInteraction called');
    console.log(object);
    if (isError) {
      setConvo(prevConvo => [...prevConvo, {name: 'Error downstream', component: <ErrorComponent message={object.message} />}]);
    } else if (object.replaceAll) {
      setConvo([object]);
    } else {
      setConvo(prevConvo => [...prevConvo, object]);
    }
  }

  useEffect(() => {
    if (convo.length === 0) {  // checks if it's the first load
      setConvo([{ name: 'Welcome', component: <WelcomeMessage onInteract={handleInteraction} /> }]); //This is an important onInteract prop that is being passed down to all child components. Make sure this doesn't bite you in the ass later.
    }
  }, []);  // empty dependency array so it only runs on the first render

  useEffect(() => {
    console.log('App component rendered');
  });


  return (
    <div className="appContainer">
      {convo.map((object, index) => 
        <div className="appContent">
          {React.cloneElement(object.component, { key: index })}
        </div>
      )}
    </div>
  );
}

export default App;