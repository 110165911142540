import React, { useState } from 'react';
import WelcomeMessage from './WelcomeMessage';
import DOMPurify from 'dompurify';
import { sendAnswerToEmail } from '../lib/api';

const CallToAction = ({ onInteract, gptContent }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showDogs, setShowDogs] = useState(false);

  const sendEmail = async (email, gptContent) => {
    try {
      const response = await sendAnswerToEmail(email, gptContent);
      if (response.status === 200) {
        console.log("Email sent successfully");
      }
    } catch (error) {
      console.error("Failed to send email", error);
    }
  };

  const handleRestart = () => {
    onInteract({
      replaceAll: true,
      name: 'Welcome',
      component: <WelcomeMessage onInteract={onInteract} />
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendEmail(email, gptContent);
    setSubmitted(true);
  };

  const handleDancingDogs = () => {
    setShowDogs(true);
  };

  return (
    <div style={{ padding: '30px', display: 'flex' }}>
      {showDogs ? (
        <div>
          <img src="https://media3.giphy.com/media/gN4ffYFgYLWY8/giphy.gif?cid=ecf05e47hwzlzbkb09w2u4ayt99j1gpgivzh3g3kln3e2ki6&ep=v1_gifs_search&rid=giphy.gif&ct=g" alt="Dancing dogs" />
          <div>
            <button onClick={handleRestart}>Take me back home</button>
          </div>
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(gptContent) }}
          />
          <p>Would you like to receive your work in your email?</p>
          {submitted ? (
            <div>
              <p>We have sent your work to your email! Would you like to try the process again?</p>
              <button onClick={handleRestart}>Yes!</button>
              <button onClick={handleDancingDogs}>No, show me dancing dogs</button>
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit}>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                <input type="submit" value="Submit Email" />
              </form>
              <button onClick={handleRestart}>Restart</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallToAction;
