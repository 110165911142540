import React, { useState } from 'react';
import { fetchPrompts } from '../lib/api';
import Prompts from './Prompts';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Spinner from "@cloudscape-design/components/spinner";
import './Categories.css';

const Categories = ({ items, onInteract, userEntity }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (item) => {
    setIsLoading(true);
    try {
      const response = await fetchPrompts(userEntity, item);
      if (response.status !== 200) {
        onInteract({ name: 'Response not okay error', message: response.statusText }, true);
      } else {
        const prompts = response.data;
        onInteract({ replaceAll: true, name: 'Prompts', component: <Prompts items={prompts} onInteract={onInteract} userEntity={userEntity} /> });
      }
    } catch (error) {
      onInteract({ name: 'Error of other sorts', message: error.message }, true);
    } finally {
      setIsLoading(false);
    }
  };

  const endsWithS = userEntity.toLowerCase().endsWith('s');

  return (
    <div className="categories">
      <h1 className="categoriesHeader">
        I'm great at helping {userEntity}{endsWithS ? '' : 's'}. Choose a category you need help with below:
      </h1>
      <SpaceBetween size="l">
        {items.map((item, index) => (
          <div 
            key={index} 
            onClick={() => handleClick(item)} 
            className={`categoryItem fadeInFromLeft ${isLoading ? 'disabled' : ''}`}
          >
            <Container
              header={
                <Header variant="h2">{item}</Header>
              }
            />
          </div>
        ))}
      </SpaceBetween>
      {isLoading && <Spinner size='large'/>}
    </div>
  );
};

export default Categories;
