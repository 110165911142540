import React, { useEffect, useState } from 'react';
import './WelcomeMessage.css';
import { fetchCategories } from '../lib/api';
import Categories from './Categories';
import Input from "@cloudscape-design/components/input";
import Spinner from "@cloudscape-design/components/spinner";


const messages = [
  'Hi! Welcome to Prompt Buddy',
  "I'm an AI with the sole purpose of helping you work smarter and faster.",
  "Simply type in your profession below, and let's see what I can do!"
];

const WelcomeMessage = ({ onInteract }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((i) => i + 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputValue.trim()) {
      setShowError(true);
      return;
    }

    setIsLoading(true);
    setShowError(false);

    try {
      const response = await fetchCategories(inputValue);
      if (response.status !== 200) {
        onInteract({ name: 'Response not okay error', message: response.statusText }, true);
      } else {
        setInputValue('');
        const items = response.data;
        onInteract({
          replaceAll: true,
          name: 'Categories',
          component: <Categories items={items} onInteract={onInteract} userEntity={inputValue} />
        });
      }
    } catch (error) {
      onInteract({ name: 'Error of other sorts', message: error.message }, true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="welcomeMessage">
      {messages.slice(0, currentIndex).map((msg, index) => (
        <p key={index} className="animatedText">{msg}</p>
      ))}
      {currentIndex >= messages.length && (
       <form onSubmit={handleSubmit} className={currentIndex >= messages.length ? "fadeIn" : ""}>
         <div className="inputWrapper">
           <div className="customInputWrapper">
             <Input
               onChange={({ detail }) => setInputValue(detail.value)}
               value={inputValue}
               placeholder="e.g. lawyer, doctor, teacher, etc."
             />
           </div>
           <button className="submitArrow" type="submit" disabled={isLoading}>
             {isLoading ? <Spinner/> : "→"}
           </button>
         </div>
         {showError && <p style={{ color: 'red', marginTop: '8px' }}>Oops! Please enter your profession before submitting.</p>}
       </form>
      )}
    </div>
  );
};

export default WelcomeMessage;
