import React from 'react';

const ErrorComponent = ({ message }) => {
  return (
    <div className="errorComponent">
      <p>An error occurred: {message}</p>
    </div>
  );
};

export default ErrorComponent;
