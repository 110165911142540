import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.promptbuddyx.ai',
});

export const fetchCategories = (userEntity) =>
  api.post('/categories', { userEntity });

export const fetchPrompts = (userEntity, category) =>
  api.post('/prompts', { userEntity, category });

export const fetchBandF = (userEntity, userInputs, prompt, gptContent) =>
  api.post('/backAndForth', { userEntity, userInputs, prompt, gptContent});


export const sendAnswerToEmail = (userEmail, gptContent) =>
  api.post('/sendAnswerToEmail', { userEmail, gptContent});